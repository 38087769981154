import React, { useState } from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import './ContactFrom.css';

export default function ContactForm() {
    const [focusedField, setFocusedField] = useState({});

    const handleFocus = (field) => {
        setFocusedField({ ...focusedField, [field]: true });
    };

    const handleBlur = (field, event) => {
        if (!event.target.value) {
            setFocusedField({ ...focusedField, [field]: false });
        }
    };

    return (
        <div className='contact-display'>
            <div className='contact-upper'>
                <h1 className='contact-head'>
                    <span className='bold'> Contact </span> <span className='bold-1'> Now </span> 
                </h1>
            </div>
            <div className='contact-grid'>
                <div className='contact-info-form'>
                    <h1 className='institute-name'>Nd Gypsum and Partition Services</h1>
                    <p className='description'>
                    At ND Gypsum & Partition Services, we specialize in delivering top-notch interior services designed to enhance the beauty, functionality, and comfort of your spaces
                    </p>
                    <div className='info-item'>
                        <FaMapMarkerAlt /> <span>ND Gypsum & Partition Services, Abu Hail - Dubai - United Arab Emirates</span>
                    </div>
                    <div className='info-item'>
                        <FaPhoneAlt /> <span>+971568522584</span>
                    </div>
                    <div className='info-item'>
                        <FaEnvelope /> <span>info@ndservices.com</span>
                    </div>
                    <div className='social-icons'>
                        <FaFacebook  href="https://www.facebook.com/profile.php?id=61565733950859&mibextid=ZbWKwL" className='icon-social' />
                        <FaInstagram href="https://www.instagram.com/invites/contact/?igsh=s84bfl308tz&utm_content=vvdsn1k" className='icon-social' />
                        <FaLinkedin  href="https://www.linkedin.com/company/104668233" className='icon-social' />
                    </div>
                    <button className='enroll-btn'>Contact Now</button>
                </div>

                <div className='contact-form'>
                    <form>
                        <div className={`form-field ${focusedField.name ? 'focused' : ''}`}>
                            <label htmlFor='name'>Name</label>
                            <input
                                type='text'
                                id='name'
                                onFocus={() => handleFocus('name')}
                                onBlur={(e) => handleBlur('name', e)}
                            />
                        </div>
                        <div className={`form-field ${focusedField.email ? 'focused' : ''}`}>
                            <label htmlFor='email'>Email</label>
                            <input className='input'
                                type='email'
                                id='email'
                                onFocus={() => handleFocus('email')}
                                onBlur={(e) => handleBlur('email', e)}
                            />
                        </div>
                        <div className={`form-field ${focusedField.phone ? 'focused' : ''}`}>
                            <label htmlFor='phone'>Phone</label>
                            <input
                                type='tel'
                                id='phone'
                                onFocus={() => handleFocus('phone')}
                                onBlur={(e) => handleBlur('phone', e)}
                            />
                        </div>
                        <div className={`form-field ${focusedField.message ? 'focused' : ''}`}>
                            <label htmlFor='message'>Message</label>
                            <textarea
                                id='message'
                                rows='4'
                                onFocus={() => handleFocus('message')}
                                onBlur={(e) => handleBlur('message', e)}
                            ></textarea>
                        </div>
                        <button type='submit' className='submit-btn'>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
