import React, { useState, useEffect } from 'react';
import './Analytics.css';
import { useInView } from 'react-intersection-observer';

function Analytics() {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const handleEffect = () => {
    document.querySelectorAll('.analytics-bar').forEach((bar, index) => {
      const widths = ["70%", "70%", "80%", "80%"];
      bar.style.width = widths[index];
    });
  };

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        handleEffect();
      }, 500);
    }
  }, [inView]);

  const [happyCustomers, setHappyCustomers] = useState(0);
  const [countriesServed, setCountriesServed] = useState(0);
  const [successfulProducts, setSuccessfulProducts] = useState(0);
  const [yearsOfExcellence, setYearsOfExcellence] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (happyCustomers < 3000) setHappyCustomers(prev => prev + 100);
      if (countriesServed < 80) setCountriesServed(prev => prev + 5);
      if (successfulProducts < 300) setSuccessfulProducts(prev => prev + 10);
      if (yearsOfExcellence < 10) setYearsOfExcellence(prev => prev + 1);
    }, 200);

    return () => clearInterval(interval);
  }, [happyCustomers, countriesServed, successfulProducts, yearsOfExcellence]);

  return (
    <div className="analytics" ref={ref}>
      <div className="analytics-grid">
        {[
          { count: happyCustomers, title: "Happy Customers" },
          { count: countriesServed, title: "Countries Served" },
          { count: successfulProducts, title: "Successful Products" },
          { count: yearsOfExcellence, title: "Years of Excellence" }
        ].map((item, index) => (
          <div className="analytics-item" key={index}>
            <p className="analytics-number">{item.count}+</p>
            <h2 className="analytics-title">{item.title}</h2>
            <div className={`analytics-bar a-bar-${index + 1}`}></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Analytics;
