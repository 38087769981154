import React from 'react';
import { useInView } from 'react-intersection-observer';
import './Reviews01.css';

function Reviews01() {

    const handleGiveReview = () => {
        window.open('https://g.page/r/CcQg1ZKy5sxxEBM/review', '_blank');
    };
    const { ref, inView } = useInView({ threshold: 0.2, triggerOnce: true });

    return (
        <div className="reviews01" ref={ref}>
            <h2 className={`updates-title ${inView ? 'fade-in' : ''}`}>Customer Reviews</h2>
            <div className="reviews-container">
                {[1, 2, 4, 5].map((num) => (
                    <img
                        key={num}
                        src={`reviews (${num}).png`}
                        alt={`Review ${num}`}
                        className={`review-img ${inView ? 'fade-in' : ''}`}
                    />
                ))}
            </div>
            <div className='review01-btn'> 
            <button className='read-more' onClick={handleGiveReview}>
                Add Review
            </button>
            </div>
        </div>
    );
}



export default Reviews01;
