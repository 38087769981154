import React from 'react'
import '../components/Landing';
import '../components/Projects2';
import Projects2 from '../components/Projects2';
import Prefooter from '../components/Prefooter';
import GypsumBoards from '../components/GypsumBoards'
import Reviews01 from '../components/Reviews01';
import GypsumServices from '../components/GypsumServices'
import QAComponent from '../components/QAComponent';
import LandingPoster from '../components/LandingPoster';
import Updates from '../components/Updates';
import Header from '../components/Header'
import LandAbout from '../components/LandAbout'
import HeaderBottom from '../components/HeaderBottom'
import Products from '../components/Products'
import Analytics from '../components/Analytics'

export default function LandingPage() {
  const title01 = "Innovative";
  const title02 = "Gypsum Partition";
  const title03 = "Solutions";
  const para1 = "At ND Gypsum & Partition Services, we specialize in delivering top-notch interior services designed to enhance the beauty, functionality, and comfort of your spaces. Whether it's transforming your home or upgrading your office, our skilled team is committed to providing high-quality solutions that cater to your unique needs. We offer a wide range of services, including gypsum partitions, false ceilings, flooring, and more, all crafted with precision and care. With years of expertise and a passion for design, we aim to bring your vision to life. Our mission is to create spaces that not only look amazing but also improve the way you live and work. Partner with us to transform your environment into something truly exceptional.";

  const title11 = "Elevate Your Space";
  const title12 = "With Innovative";
  const title13 = "Solutions";
  const para2 = "Looking to enhance your space with stylish and functional design solutions? Our custom-designed partition systems provide a versatile way to transform any environment to suit your unique style and needs. Ideal for both residential and commercial spaces, these partitions help create distinct areas without compromising flow or aesthetics. Whether optimizing an office layout, dividing living spaces, or improving insulation and acoustics, our solutions offer flexibility and comfort. Designed with modern spaces in mind, they seamlessly blend function and beauty to elevate your environment.";

  return (
    <div>
      <Header />
      <HeaderBottom />
      <LandAbout
        type={1}
        title01={title01}
        title02={title02}
        title03={title03}
        para1={para1} />
      <Products />
      <LandingPoster />
      <GypsumBoards />
      <GypsumServices />
      <Projects2 />
      <Analytics/>
      <Reviews01/>
      <QAComponent />
      <Prefooter />
    </div>
  )
}
