import React, { useEffect } from "react";
import "./Header.css";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

const Header = () => {

    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    const handleEffect = () => {
        const element = document.querySelector('#rect-1');
        element.style.width = "100%";
        const element1 = document.querySelector('#header-grid');
        element1.style.marginLeft = "20px";
        element1.style.opacity = "1";
    }

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                handleEffect();
            }, 1000);
        }
    }, [inView])

    return (
        <div className="header" ref={ref}>
            <video className="video-bg" src="/video02.mp4" autoPlay loop muted />
            <div className="header-content">
                <div className="header-grid" id="header-grid">
                    <div className="header-left">
                        <h1 className="head-white">
                            WELCOME TO
                        </h1>
                        <h1 className="head-white-1">
                            nd gypsum partition
                        </h1>
                        <p className="para-white">
                        Looking for premium gypsum ceiling and partition services? We specialize in drywall, false ceilings, and partitions for homes and businesses, using fire-resistant, soundproof, and energy-efficient materials for lasting quality and style.
                        </p>
                        <Link className="link" to="/contact">
                            <button className="contact-btn-2">
                                Contact Now
                            </button>
                        </Link>
                    </div>
                    <div className="header-next">
                        <hr className="header-line"></hr>
                        <hr className="header-line"></hr>
                        <hr className="header-line"></hr>
                        <hr className="header-line"></hr>
                        <hr className="header-line"></hr>
                        <hr className="header-line"></hr>
                        <hr className="header-line"></hr>
                        <hr className="header-line"></hr>
                        <hr className="header-line"></hr>
                        <hr className="header-line"></hr>
                    </div>
                </div>
                <div className="header-bottom">
                    <div >

                    </div>
                    <div className="rect-1" id="rect-1">

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
