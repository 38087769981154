import React from 'react'
import '../components/Banner'
import Banner from '../components/Banner'
import About from '../components/About'
import Services from '../components/Services'
import Prefooter from '../components/Prefooter'
import Content from '../components/Content'
import Analytics from '../components/Analytics'
import Reviews01 from '../components/Reviews01';

function Home() {
    return (
        <div className='Home'>
            <Banner/>
            <About/>
            <Services/>
            <Content/>
            <Analytics/>
            <Reviews01/>
            <Prefooter/>
        </div>
    )
}

export default Home