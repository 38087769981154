import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './LandAbout.css';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function LandAbout({ type, title01, title02, title03, para1, analyticsTitle1, analyticsTitle2, analyticsTitle3, analyticsPara }) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.9,
  });

  const handleEffect = () => {
    document.querySelector(`#head-bottom-${type}`).style.opacity = "1";
  };

  const handleParaEffect = () => {
    const paraElement = document.querySelector(`#para-dark-${type}`);
    if (paraElement) {
      paraElement.style.opacity = "1";
      paraElement.style.marginTop = "-5px";
    }
  };

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        handleEffect();
        setTimeout(() => {
          handleParaEffect();
        }, 500);
      }, 200);
    }
  }, [inView, type]);

  return (
    <div className="land-about" ref={ref}>
      <div className="land-about-left">
        <h1 className="head-bottom" id={`head-bottom-${type}`}>
          {type === 1 ? (
            <>
              {title01} <span className={inView ? 'slide-background' : ''}>{title02}</span> {title03}
            </>
          ) : (
            <>
              {analyticsTitle1} <span className={inView ? 'slide-background' : ''}>{analyticsTitle2}</span> {analyticsTitle3}
            </>
          )}
        </h1>
        <Link to={'/about'}>
          <button className="read-more-btn">
            Read More
            <FaArrowRight className="arrow-icon" />
          </button>
        </Link>

      </div>
      <div className="land-about-next">
        <p className="para-dark" id={`para-dark-${type}`}>
          {type === 1 ? para1 : analyticsPara}
        </p>
      </div>
    </div>
  );
}

export default LandAbout;
